import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import FCLink from '../fc_link/fc_link';

const data = [
  {
    title: 'MOST CREATIVE PEOPLE IN BUSINESS',
    description:
      'Leaders who are shaping the future of business in creative ways.',
    logo:
      'https://images.fastcompany.net/image/upload/v1536778764/fcweb/mcpnewlogo_d7dgyh.svg',
    image:
      'https://images.fastcompany.net/image/upload/w_280,ar_16:9,c_fill,g_auto,q_auto:best/wp-cms/uploads/2023/07/p-1-90909686-nick-cave-knoll-textile-collection.webp',
    link: 'https://www.fastcompany.com/most-creative-people/list'
  },
  {
    title: 'INNOVATION by design',
    description:
      'The best products, services, interfaces, and design ideas in business.',
    logo:
      'https://images.fastcompany.net/image/upload/v1536781102/fcweb/IBD_small-icon-50x50px-fix_s2op0e.svg',
    image:
      'https://images.fastcompany.net/image/upload/w_280,ar_16:9,c_fill,g_auto,q_auto:best/wp-cms/uploads/2023/08/p-5a-ibd-2023-web-opener-horizontal-copy.webp',
    link: 'https://www.fastcompany.com/innovation-by-design/list'
  },
  {
    title: 'Most Innovative Companies',
    description:
      'Fast Company’s annual ranking of businesses that are making an outsize impact.',
    logo:
      'https://images.fastcompany.net/image/upload/v1536778764/fcweb/micnewlogo_ctvsdy.svg',
    image:
      'https://assets.fastcompany.com/image/upload/v1710944060/mic_tacobell.webp',
    link: 'https://www.fastcompany.com/most-innovative-companies/list'
  },
  {
    title: 'World Changing Ideas',
    description:
      'Products, concepts, and policies that are pursuing innovation for good.',
    logo:
      'https://images.fastcompany.net/image/upload/v1536779048/fcweb/wcinewlogo_jebah8.svg',
    image:
      'https://images.fastcompany.net/image/upload/wp-cms/uploads/2023/04/WCI_MegatronCrop.webp',
    link: 'https://www.fastcompany.com/world-changing-ideas/list'
  }
];

const FranchiseRow = (config, showLogo) => {
  const { initialPageLoad } = config;

  const settings = {
    arrows: false,
    infinite: true,
    className: 'row franchise-showcase',
    slidesToScroll: 1,
    slide: '.franchise',
    dots: true,
    dotsClass: 'franchise-pagination',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1024
      },
      {
        breakpoint: 100000,
        settings: 'unslick'
      }
    ]
  };

  const renderFranchies = () =>
    data.map((franchise, index) => (
      <div className="franchise" key={`franchise-${index}`}>
        <FCLink title={franchise.title} to={franchise.link} config={config}>
          <figure className="franchise__photo">
            <img
              alt={franchise.title}
              height="155"
              width="276"
              src={franchise.image
                .replace('.png', '.webp')
                .replace('.jpg', '.webp')}
            />
          </figure>
          <div className="franchise__head">
            <h2 className="franchise__head--title">{franchise.title}</h2>
            {showLogo && (
              <div className="franchise__head--logo">
                <img src={franchise.logo} alt={franchise.title} />
              </div>
            )}
          </div>
          <p className="franchise__description">{franchise.description}</p>
        </FCLink>
      </div>
    ));

  if (initialPageLoad) {
    return <div className="row franchise-showcase">{renderFranchies()}</div>;
  }

  return (
    <Slider {...settings} key="franchise-showcase">
      {renderFranchies()}
    </Slider>
  );
};

FranchiseRow.defaultProps = {
  showLogo: false
};

FranchiseRow.propTypes = {
  config: PropTypes.shape({}).isRequired,
  showLogo: PropTypes.bool
};

export default FranchiseRow;
