import React from 'react';
import PropTypes from 'prop-types';
import Slug from '../../components/slug/slug';
import Card from '../../components/card/card';
import AdContainer from '../ad/AdContainer';
import { imageSizesLookup } from '../../../utils/cloudinary_asset_util';

const FeatureTerm = ({ data, config }) => {
  const { posts = [], name = 'Fast Company Premium', slug = '/premium' } = data;
  const formattedPosts = (() =>
    posts.map(post => {
      const p = post;
      p.assetSizes = imageSizesLookup.poster;
      return p;
    }))();
  const splitPosts = [formattedPosts.slice(0, 2), formattedPosts.slice(2)];
  return (
    <div className="feature_term">
      <Slug
        slugName={name}
        linkTo={slug}
        showTooltip={true}
        className="noBorder"
      />
      {splitPosts.map(rowPosts => (
        <div key={rowPosts?.[0]?.id} className="row">
          {rowPosts.map(post => (
            <Card
              key={post?.id}
              metadata={post}
              config={config}
              noBorder={true}
              noPremiumIcon={true}
            />
          ))}
        </div>
      ))}
      <a title="All Premium Stories" href="/premium">
        <button>All Premium Stories</button>
      </a>
      <div className="homepage-page__ft-ad-container">
        <AdContainer type="break" />
      </div>
    </div>
  );
};

FeatureTerm.propTypes = {
  config: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    posts: PropTypes.array.isRequired
  }).isRequired
};

export default FeatureTerm;
